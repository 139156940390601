// Imports
// ------
import React from 'react';
import AnimatedText from '@parts/AnimatedText';
import Service from './Service';
import { useDato } from './data';

// Styles
// ------
import { Jacket } from './styles';

// Component
// ------
function Listing() {
	// NOTE • Data
	const {
		services: { edges },
		servicePage: { animatedText },
	} = useDato();

	return (
		<Jacket>
			<AnimatedText text={animatedText} />

			{edges.map((b, i) => (
				<Service
					key={b.node.id}
					image={b.node.image}
					title={b.node.title}
					text={b.node.text}
					button={b.node.button}
					isEven={(i + 1) % 2 == 0}
				/>
			))}
		</Jacket>
	);
}

export default Listing;
