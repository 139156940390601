// Imports
// ------
import React from 'react';
import Hero from './Hero';
import Listing from './Listing';
import Mission from './Mission';
import LatestNews from '@parts/LatestNews';
import CallToAction from '@parts/CallToAction';

// Component
// ------
function Services() {
	return (
		<>
			<Hero />
			<Listing />
			<Mission />
			<LatestNews />
			<CallToAction />
		</>
	);
}

export default Services;
