import { useStaticQuery, graphql } from 'gatsby';

export const useDato = () => {
	const data = useStaticQuery(
		graphql`
			query ServicesMission {
				dato: datoCmsServicesPage {
					text: missionStatement
					image: missionImage {
						alt
						data: gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
					}
				}
			}
		`
	);
	return data.dato;
};
