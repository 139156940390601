// Imports
// -------
import { useStaticQuery, graphql } from 'gatsby';

// Exports
// ------
export const useDato = () => {
	const data = useStaticQuery(
		graphql`
			query ServicesHero {
				services: datoCmsServicesPage {
					title: heroTitle
					titleMobile: heroTitleMobile
					excerpt: heroExcerpt
					image: heroImage {
						alt
						data: gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
					}
				}
			}
		`
	);
	return data.services;
};
