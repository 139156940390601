// Imports
// -------
import { useStaticQuery, graphql } from 'gatsby';

// Exports
// ------
export const useDato = () => {
	const data = useStaticQuery(
		graphql`
			query ServicesListing {
				services: allDatoCmsServicePost {
					edges {
						node {
							id: originalId
							title: serviceTitle
							text: serviceInformation
							button: serviceButton {
								slug
							}
							image: serviceImage {
								alt
								data: gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
							}
						}
					}
				}

				servicePage: datoCmsServicesPage {
					animatedText
				}
			}
		`
	);
	return data;
};
