// Imports
// ------
import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Row, Column } from '@waffl';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useDato } from './data';

// Styles
// ------
import { Jacket, Image, Content, Text } from './styles';

// Component
// ------
gsap.registerPlugin(ScrollTrigger);

function Mission() {
	// NOTE • Data
	// ------
	const { image, text } = useDato();

	// NOTE • Refs
	const target = useRef();
	const trigger = useRef();

	// NOTE • Onload - Animate Scroll
	useEffect(() => {
		const timer = setTimeout(() => {
			gsap.to(target.current, {
				scale: 1,
				rotate: `0deg`,
				opacity: 0.6,
				ease: 'none',
				scrollTrigger: {
					trigger: trigger.current,
					start: '0% 50%',
					end: '80% 50%',
					scrub: 0.5,
					markers: false,
				},
			});

			ScrollTrigger.matchMedia({
				'(min-width: 1024px)': () => {
					gsap.to(trigger.current, {
						scale: 0.809,
						ease: 'none',
						scrollTrigger: {
							trigger: trigger.current,
							start: '100% 100%',
							end: '130% 100%',
							scrub: 0.5,
							markers: false,
						},
					});
				},
			});
		}, 250);

		return () => {
			clearTimeout(timer);
		};
	}, []);

	return (
		<Jacket>
			<div ref={trigger}>
				<Image>
					<div ref={target}>
						<GatsbyImage image={image.data} alt={image.alt} />
					</div>
				</Image>

				<Content pad>
					<Row isExpanded>
						<Column
							small={12}
							medium={10}
							pushOnMedium={1}
							large={8}
							pushOnLarge={2}
							mpad>
							<Text>{text}</Text>
						</Column>
					</Row>
				</Content>
			</div>
		</Jacket>
	);
}

export default Mission;
