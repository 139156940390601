// Imports
// ------
import React from 'react';
import Seo from '@parts/Seo';
import PageTransition from '@parts/PageTransition';
import Services from '@parts/Services';
import { graphql } from 'gatsby';

// Component
// ------
function ServicesPage({ transitionStatus, entry, exit, data }) {
	// NOTE • Props
	const transProps = {
		status: transitionStatus,
		entry: entry,
		exit: exit,
	};

	return (
		<>
			<Seo content={data.page.seo} />

			<PageTransition {...transProps}>
				<Services />
			</PageTransition>
		</>
	);
}

export default ServicesPage;

// GraphQL
// ------
export const query = graphql`
	query Services {
		page: datoCmsServicesPage {
			seo: seoMeta {
				title
				desc: description
				card: twitterCard
				image {
					url
				}
			}
		}
	}
`;
