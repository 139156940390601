// Imports
// ------
import React, { useState, useEffect, useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Row, Column } from '@waffl';
import { useDato } from './data';
import { GatsbyImage } from 'gatsby-plugin-image';
import { loaderState } from '@states/loader';
import { observer } from 'mobx-react-lite';

// Styles
// ------
import 'splitting/dist/splitting.css';
import 'splitting/dist/splitting-cells.css';
import { Jacket, Title, Excerpt, Image, Media } from './styles';

// Component
// ------
gsap.registerPlugin(ScrollTrigger);

function Hero() {
	// NOTE • States
	const [offset, setOffset] = useState();
	const [titleAni, setTitleAni] = useState(false);

	// NOTE • refs
	const excerptRef = useRef();
	const target = useRef();
	const trigger = useRef();
	const titleRef = useRef();

	// NOTE • Data
	const { titleMobile, excerpt, image } = useDato();

	// NOTE • Split Title Characters
	useEffect(() => {
		let Splitting;
		if (typeof document !== 'undefined') {
			Splitting = require('splitting');

			Splitting({
				target: titleRef.current,
				by: 'chars',
			});
		}
	}, []);

	// NOTE • Onload - Offset image
	useEffect(() => {
		const calculate = () => {
			setOffset(excerptRef.current.offsetHeight);
		};

		window.addEventListener('resize', calculate);

		return () => {
			window.removeEventListener('resize', calculate);
		};
	}, []);

	// NOTE • Onload - Animate Scroll
	useEffect(() => {
		const timer = setTimeout(() => {
			gsap.to(target.current, {
				scale: 1,
				ease: 'none',
				scrollTrigger: {
					trigger: trigger.current,
					start: '0% 50%',
					end: '100% 50%',
					scrub: 0.5,
					markers: false,
				},
			});
		}, 250);

		return () => {
			clearTimeout(timer);
		};
	}, []);

	// NOTE • Check for loader status
	useEffect(() => {
		if (loaderState.isNeeded === false) {
			setTitleAni(true);
		}
	}, [loaderState.isNeeded]);

	return (
		<Jacket ref={trigger}>
			<Row isExpanded>
				<Column small={12} medium={10} pushOnMedium={1} mpad>
					<Title ref={titleRef} isAnimating={titleAni}>
						{titleMobile}
					</Title>
				</Column>
			</Row>

			<Row isExpanded>
				<Column small={12} medium={10} pushOnMedium={1} large={4} mpad>
					<Excerpt ref={excerptRef}>{excerpt}</Excerpt>
				</Column>
			</Row>

			<Media>
				<Image offset={offset}>
					<div ref={target}>
						<GatsbyImage image={image.data} alt={image.alt} />
					</div>
				</Image>
			</Media>
		</Jacket>
	);
}

export default observer(Hero);
