// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup, Div, H4, P } from '@tackl';

// Exports
// ------
export const Jacket = styled(Div)(
	(props) => css`
		width: 100%;
	`
);

export const Content = styled.div(
	(props) => css`
		display: flex;
		flex-flow: column;
		align-items: flex-start;
		justify-content: center;
		height: 100%;

		${breakup.large`
			opacity: 0;
			transform: translateY(2.4rem);
		`}
	`
);

const sharedStyles = (props) => css`
	color: ${props.theme.bc4};
`;

export const Title = styled(H4)(
	(props) => css`
		${sharedStyles}
	`
);

export const Text = styled(P)(
	(props) => css`
		${sharedStyles}
		margin: 1.8rem 0;
		opacity: 0.6;

		${breakup.large` margin: 2.4rem 0; `}
	`
);

export const Image = styled.figure(
	(props) => css`
		position: relative;
		overflow: hidden;
		min-height: calc(100vw - 4.8rem);
		height: calc(var(--vhs, 1vh) * 50);
		margin: 0 0 2.4rem 0;

		${breakup.large`
            min-height: 48rem;
            height: 75vh;
            max-height: 60rem;
            margin: 0;
			border-radius: ${props.isEven ? `0 24rem 0 0` : `24rem 0 0 0`};
        `}

		> div {
			position: relative;
			width: 100%;
			height: 100%;

			${breakup.large`
				transform: scale(1.2);
			`}
		}

		.gatsby-image-wrapper {
			position: relative;
			width: 100%;
			height: 100%;
			overflow: hidden;

			img {
				border-radius: ${props.isEven ? `0 12rem 0 0` : `12rem 0 0 0`};

				${breakup.large`
					border-radius: 0;
				`}
			}
		}
	`
);
