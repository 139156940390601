// Imports
// ------
import styled, { css, keyframes } from 'styled-components';
import { breakup, Section, H1, P } from '@tackl';

// Exports
// ------
export const Jacket = styled(Section)(
	(props) => css`
		position: relative;
		margin: 6rem 0 0 0;
		padding: ${props.theme.padS} 0 0;
		overflow: hidden;

		${breakup.medium`
			padding: ${props.theme.padM} 0;
		`}

		${breakup.large`
			padding: ${props.theme.padL} 0 calc(${props.theme.padL} * 2);
		`}

		&:before {
			content: '';
			position: absolute;
			top: ${props.theme.padL};
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 1;
			${props.theme.grad(0, props.theme.bc4, `${props.theme.bc4}00`)}
		}
	`
);

const slideIn = keyframes`
    from {
        transform: translateY(-1.2rem) rotate(-6deg);
        opacity: 0;
    }

    to {
        transform: translateY(0rem) rotate(0deg);
        opacity: 1;
    }
`;

export const Title = styled(H1)(
	(props) => css`
		position: relative;
		z-index: 3;

		.word {
			display: inline-flex;
		}

		.char {
			display: inline-flex;
			opacity: 0;

			${props.isAnimating &&
			css`
				animation: ${slideIn} 0.5s cubic-bezier(0.5, 0, 0.5, 1) both;
				animation-delay: calc(50ms * var(--char-index));
			`}
		}
	`
);

export const Excerpt = styled(P)(
	(props) => css`
		position: relative;
		z-index: 3;
		padding: 2.4rem 0 ${props.theme.padS} 0;

		${breakup.medium` padding: 3.6rem 0 ${props.theme.padM} 0; `}

		${breakup.large` padding: 6rem 0 0 0; `}
	`
);

export const Media = styled.div(
	(props) => css`
		position: relative;
		z-index: 2;
		overflow: hidden;

		${breakup.large`
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			top: 0;
		`}

		&:before {
			content: '';
			position: absolute;
			z-index: 1;
			bottom: 0;
			left: -50vw;
			right: 0;
			height: ${props.theme.padL};
			background: ${props.theme.white};
		}
	`
);

export const Image = styled.figure(
	(props) => css`
		position: relative;
		z-index: 1;
		overflow: hidden;
		border-radius: 12rem 0 0 0;

		${breakup.large`
			position: absolute;
			bottom: 0; right: 0;
			left: 50vw;
			top: calc(50% - ${props.offset}px);
			width: 50vw;
			border-radius: 24rem 0 0 0;
		`}

		> div {
			position: relative;
			z-index: 2;
			width: 100%;
			height: 100%;
			transform: scale(1.2);
		}

		.gatsby-image-wrapper {
			position: relative;
			width: 100%;
			height: 100%;
		}
	`
);
