// Imports
// ------
import React, { useRef, useEffect } from 'react';
import Button from '@parts/Button';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import useLabelPicker from '@utils/useLabelPicker';
import { Row, Column } from '@waffl';
import { GatsbyImage } from 'gatsby-plugin-image';

// Styles
// ------
import { Jacket, Content, Title, Text, Image } from './styles';

// Component
// ------
gsap.registerPlugin(ScrollTrigger);

function Service({ title, text, button, image, isEven }) {
	// NOTE • Column Props
	const oddPropsText = {
		small: 12,
		medium: 10,
		pushOnMedium: 1,
		mpad: true,
		large: 4,
		pullOnLarge: 4,
	};

	const oddPropsImage = {
		small: 12,
		large: 5,
		pushOnLarge: 6,
		mpad: true,
	};

	const evenPropsText = {
		small: 12,
		medium: 10,
		pushOnMedium: 1,
		mpad: true,
		large: 4,
		pushOnLarge: 2,
	};

	const evenPropsImage = {
		small: 12,
		pushOnLarge: 1,
		large: 5,
		mpad: true,
	};

	// NOTE • Refs
	const target = useRef();
	const trigger = useRef();
	const contentRef = useRef();

	// NOTE • Label Picker
	const buttonLabel = useLabelPicker(button.slug);

	// NOTE • Prop Decider
	const imageProps = isEven ? evenPropsImage : oddPropsImage;
	const textProps = isEven ? evenPropsText : oddPropsText;

	// NOTE • Onload: Make animations
	useEffect(() => {
		const timer = setTimeout(() => {
			ScrollTrigger.matchMedia({
				'(min-width: 1024px)': () => {
					gsap.to(target.current, {
						scale: 1,
						ease: 'none',
						scrollTrigger: {
							trigger: trigger.current,
							start: '0% 50%',
							end: '100% 50%',
							scrub: 0.5,
							markers: false,
						},
					});

					gsap.to(contentRef.current, {
						opacity: 1,
						y: 0,
						ease: 'none',
						scrollTrigger: {
							trigger: trigger.current,
							start: '0% 50%',
							end: '100% 50%',
							scrub: false,
							markers: false,
							toggleActions: 'play none none none',
						},
					});
				},
			});
		}, 250);

		return () => {
			clearTimeout(timer);
		};
	}, []);

	return (
		<Jacket marBottom ref={trigger}>
			<Row isExpanded isEqual>
				<Column {...imageProps}>
					<Image isEven={isEven}>
						<div ref={target}>
							<GatsbyImage image={image.data} alt={image.alt} />
						</div>
					</Image>
				</Column>

				<Column {...textProps}>
					<Content ref={contentRef}>
						<Title>{title}</Title>
						<Text>{text}</Text>
						<Button label={buttonLabel} to={button.slug} />
					</Content>
				</Column>
			</Row>
		</Jacket>
	);
}

export default React.memo(Service);
